import { useCompanyStore } from "@/store/company";
import { usePostHog } from "posthog-js/react";
import { Suspense, lazy, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Navbar, PageLoader, RequireAuth } from "./components";
import { ErrorComponent } from "./components/ErrorComponent/ErrorComponent";
import { useHasReport } from "./hooks/useHasReport";
import { VersionDisplay } from "./layouts/VersionDisplay/VersionDisplay";
import { useUserStore } from "./store/user";

const AdministrativeAreaGoalsSales = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsSales"
    )
);
const AdminsitrativeAreaGoalsPurchases = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsPurchases"
    )
);
const AdministrativeAreaGoalsSalesDetails = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsSales/AdministrativeAreaGoalsSalesDetails"
    )
);
const AdministrativeArea = lazy(() => import("./routes/AdministrativeArea"));
const DRE = lazy(() => import("./routes/Reports/Board/DRE"));
const TiresGathering = lazy(
  () => import("./routes/Reports/Board/TiresGathering")
);
const BudgetAnalysis = lazy(
  () => import("./routes/Reports/Board/BudgetAnalysis")
);
const LogisticChecklist = lazy(
  () => import("./routes/Reports/Logistic/LogisticChecklist")
);
const AdministrativeAreaCompanyInformation = lazy(
  () =>
    import("./routes/AdministrativeArea/AdministrativeAreaCompanyInformation")
);
const AdministrativeAreaCommissions = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaCommissions")
);
const AdministrativeAreaPermissions = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaPermissions")
);
const AdministrativeAreaIndicators = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaIndicators")
);
const AdministrativeAreaParametrizationFinancial = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationFinancial"
    )
);
const AdministrativeAreaParametrizationCheckin = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationCheckin"
    )
);
const AdministrativeAreaParametrizationPotential = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationPotential"
    )
);
const AdministrativeAreaParametrizationSellers = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationSellers"
    )
);
const AdministrativeAreaParametrizationMarketClassificationTabs = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationMarketClassification"
    )
);
const AdministrativeAreaParametrizationReturns = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationReturns"
    )
);
const AdministrativeAreaParametrizationPortfolio = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationPortfolio"
    )
);
const AdministrativeAreaParametrizationCalendar = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationCalendar"
    )
);
const AdministrativeAreaParametrizationInventory = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationInventory"
    )
);
const AdministrativeAreaUsers = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaUsers")
);
const AdministrativeAreaCampaigns = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaCampaigns")
);
const AdministrativeAreaCampaignsDetails = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaCampaigns/AdministrativeAreaCampaignsDetails"
    )
);
const AmericanaReceivableMonitoring = lazy(
  () => import("./routes/Reports/Financial/AmericanaReceivableMonitoring")
);
const ReceivableMonitoring = lazy(
  () => import("./routes/Reports/Financial/ReceivableMonitoring")
);
const CurrentInventory = lazy(
  () => import("./routes/Reports/Inventory/CurrentInventory")
);
const InventoryMovement = lazy(
  () => import("./routes/Reports/Inventory/InventoryMovement")
);
const TanksControl = lazy(
  () => import("./routes/Reports/Inventory/TanksControl")
);
const CustomerFrequency = lazy(
  () => import("./routes/Reports/Sales/CustomerFrequency")
);
const GlobalInventory = lazy(
  () => import("./routes/Reports/Inventory/GlobalInventory")
);
const MarketClassification = lazy(
  () => import("./routes/Reports/Sales/MarketClassification")
);
const SalesByLocation = lazy(
  () => import("./routes/Reports/Sales/SalesByLocation")
);
const Dashboard = lazy(() => import("./routes/Dashboard"));
const EvolutionCurve = lazy(
  () => import("./routes/Reports/Sales/EvolutionCurve")
);
const ExchangeCenter = lazy(
  () => import("./routes/Reports/Inventory/ExchangeCenter")
);
const InventoryAdjustment = lazy(
  () => import("./routes/Reports/Inventory/InventoryAdjustment")
);
const PurchaseCenter = lazy(
  () => import("./routes/Reports/Inventory/PurchaseCenter")
);
const GmadCampaign = lazy(
  () => import("./routes/Reports/SalesSupervisor/GmadCampaigns")
);
const BrandGoals = lazy(
  () => import("./routes/Reports/SalesSupervisor/BrandGoals")
);
const CustomerOneDepartment = lazy(
  () => import("./routes/Reports/SalesSupervisor/CustomerOneDepartment")
);
const MahleAward = lazy(
  () => import("./routes/Reports/SalesSupervisor/MahleAward")
);
const GmadRankingExtract = lazy(
  () => import("./routes/Reports/StoreGroup/StoreGroupRankingExtract")
);
const GmadCampaignsExtract = lazy(
  () => import("./routes/Reports/StoreGroup/CampaignsExtract")
);
const GmadSalesmanProgram = lazy(
  () => import("./routes/Reports/StoreGroup/SalesmanProgram")
);
const GoalTracking = lazy(() => import("./routes/Reports/Sales/GoalTracking"));
const InventoryReturns = lazy(
  () => import("./routes/Reports/Inventory/InventoryReturns")
);
const Login = lazy(() => import("./routes/Login"));
const NotFound = lazy(() => import("./routes/NotFound"));
const RenderPowerBI = lazy(() => import("./routes/Reports/RenderPowerBI"));
const SalesAnalysis = lazy(
  () => import("./routes/Reports/Sales/SalesAnalysis")
);
const CampaignExtract = lazy(
  () => import("./routes/Reports/Sales/CampaignExtract")
);
const Sales1kLiters = lazy(
  () => import("./routes/Reports/Sales/Sales1kLiters")
);
const IndicatorBoard = lazy(
  () => import("./routes/Reports/Sales/IndicatorBoard")
);
const Commissions = lazy(() => import("./routes/Reports/Sales/Commissions"));
const FuelDiscount = lazy(() => import("./routes/Reports/Sales/FuelDiscount"));
const FuelDiscountNew = lazy(
  () => import("./routes/Reports/Consiliation/SalesConsiliation")
);
const FuelTargets = lazy(() => import("./routes/Reports/Sales/FuelTargets"));
const StoreGroup = lazy(() => import("./routes/Reports/StoreGroup/StoreGroup"));
const FleetExpenses = lazy(
  () => import("./routes/Reports/Logistic/FleetExpenses")
);
const UserProfile = lazy(() => import("./routes/UserProfile"));
const PaymentSales = lazy(() => import("./routes/Reports/Sales/PaymentSales"));
const HelpCenter = lazy(() => import("./routes/HelpCenter"));
const Logs = lazy(() => import("./routes/Logs"));
const CheckIn = lazy(() => import("./routes/ComplementaryApps/CheckIn"));
const HelpCenterInitial = lazy(
  () => import("./routes/HelpCenter/HelpCenterInitial")
);
const HelpCenterFaq = lazy(() => import("./routes/HelpCenter/HelpCenterFaq"));
const HelpCenterVideoListByPlatform = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideoListByPlatform")
);
const HelpCenterVideoListByCategory = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideoListByCategory")
);
const HelpCenterVideo = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideo")
);
const FinancialAccountsControl = lazy(
  () => import("./routes/Reports/Financial/FinancialAccountsControl")
);
const AccountsControlCredit = lazy(
  () => import("./routes/Reports/Financial/AccountsControlCredit")
);
const AccountsControlExpense = lazy(
  () => import("./routes/Reports/Financial/AccountsControlExpense")
);
const AccountsControlReceive = lazy(
  () => import("./routes/Reports/Financial/AccountsControlReceive")
);
const Carriers = lazy(() => import("./routes/Reports/Financial/Carriers"));
const CashFlow = lazy(
  () => import("./routes/Reports/FinancialSupervisor/CashFlow")
);
const Taxes = lazy(() => import("./routes/Reports/FinancialSupervisor/Taxes"));
const PortfolioManagement = lazy(
  () => import("./routes/Reports/Sales/PortfolioManagement")
);
const StoreGroupCampaings = lazy(
  () => import("./routes/Reports/StoreGroup/StoreGroupCampaings")
);
const SalesAnalysisStream = lazy(
  () => import("./routes/Reports/Sales/SalesAnalysisStream")
);
const VisitTracking = lazy(
  () => import("./routes/Reports/CheckIn/VisitTracking")
);
const PurchaseOrder = lazy(
  () => import("./routes/Reports/Purchase/PurchaseOrder")
);
const PurchaseAnalysis = lazy(
  () => import("./routes/Reports/Purchase/PurchaseAnalysis")
);
const GasStationComparsion = lazy(
  () => import("./routes/Reports/Board/GasStationComparsion")
);

export default function App() {
  const posthog = usePostHog();

  const storedUser = useUserStore((store) => store.user);
  const storedCompany = useCompanyStore((store) => store.company.nome_fantasia);

  useEffect(() => {
    posthog.identify(storedUser?.id.toString(), {
      email: storedUser?.email,
      name: storedUser?.first_name,
      company: storedCompany,
    });
  });

  return (
    <>
      <VersionDisplay />

      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/404" element={<NotFound />} />
          <Route
            element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/check-in" element={<CheckIn />} />
            <Route path="/help-center" element={<HelpCenter />}>
              <Route path="/help-center" element={<HelpCenterInitial />} />
              <Route path="/help-center/faq" element={<HelpCenterFaq />} />
              <Route
                path="/help-center/plataforma/:platform"
                element={<HelpCenterVideoListByPlatform />}
              />
              <Route
                path="/help-center/categoria/:category"
                element={<HelpCenterVideoListByCategory />}
              />
              <Route
                path="/help-center/video/:platform/:category/:video"
                element={<HelpCenterVideo />}
              />
            </Route>
            <Route path="/administrative" element={<AdministrativeArea />}>
              <Route
                path="/administrative/company"
                element={<AdministrativeAreaCompanyInformation />}
              />
              <Route
                path="/administrative/campaigns"
                element={<AdministrativeAreaCampaigns />}
              />
              <Route
                path="/administrative/campaigns/:campaignId"
                element={<AdministrativeAreaCampaignsDetails />}
              />
              <Route
                path="/administrative/commissions"
                element={<AdministrativeAreaCommissions />}
              />
              <Route
                path="/administrative/goals/sales"
                element={<AdministrativeAreaGoalsSales />}
              />
              <Route
                path="/administrative/goals/sales/:goalId"
                element={<AdministrativeAreaGoalsSalesDetails />}
              />
              <Route
                path="/administrative/goals/purchases"
                element={<AdminsitrativeAreaGoalsPurchases />}
              />
              <Route
                path="/administrative/indicators"
                element={<AdministrativeAreaIndicators />}
              />
              <Route
                path="/administrative/permissions"
                element={<AdministrativeAreaPermissions />}
              />
              <Route
                path="/administrative/parameterization/potencial"
                element={<AdministrativeAreaParametrizationPotential />}
              />
              <Route
                path="/administrative/parameterization/financial"
                element={<AdministrativeAreaParametrizationFinancial />}
              />
              <Route
                path="/administrative/parameterization/checkin"
                element={<AdministrativeAreaParametrizationCheckin />}
              />
              <Route
                path="/administrative/parameterization/sellers"
                element={<AdministrativeAreaParametrizationSellers />}
              />
              <Route
                path="/administrative/parameterization/market-classification"
                element={
                  <AdministrativeAreaParametrizationMarketClassificationTabs />
                }
              />
              <Route
                path="/administrative/parameterization/returns"
                element={<AdministrativeAreaParametrizationReturns />}
              />
              <Route
                path="/administrative/parameterization/portfolio"
                element={<AdministrativeAreaParametrizationPortfolio />}
              />
              <Route
                path="/administrative/parameterization/calendar"
                element={<AdministrativeAreaParametrizationCalendar />}
              />
              <Route
                path="/administrative/parameterization/inventory"
                element={<AdministrativeAreaParametrizationInventory />}
              />
              <Route
                path="/administrative/users"
                element={<AdministrativeAreaUsers />}
              />
            </Route>
            <Route
              path="/reports/americana_inadimplencia"
              element={<AmericanaReceivableMonitoring />}
            />
            <Route
              path="/reports/rel-inadimplencia"
              element={<ReceivableMonitoring />}
            />
            <Route path="/reports/rel_dre" element={<DRE />} />
            <Route
              path="/reports/rel-coleta-pneu"
              element={<TiresGathering />}
            />
            <Route
              path="/reports/rel-frota-flav-checklist"
              element={<LogisticChecklist />}
            />
            <Route
              path="/reports/analise-orcamento"
              element={<BudgetAnalysis />}
            />
            <Route path="/reports/analise-vendas" element={<SalesAnalysis />} />
            <Route
              path="/reports/analise-vendas-stream"
              element={<SalesAnalysisStream />}
            />
            <Route
              path="/reports/gestao-carteira"
              element={<PortfolioManagement />}
            />
            <Route
              path="/reports/rel-vendas-classificacao-mercadologica"
              element={<MarketClassification />}
            />
            <Route
              path="/reports/rel-vendas-localizacao"
              element={<SalesByLocation />}
            />
            <Route path="/reports/comissoes" element={<Commissions />} />
            <Route
              path="/reports/extrato-campanha"
              element={<CampaignExtract />}
            />
            <Route path="/reports/mahle-premiacao" element={<MahleAward />} />
            <Route
              path="/reports/rel-vendas-pagamentos"
              element={<PaymentSales />}
            />
            <Route
              path="/reports/central-trocas"
              element={<ExchangeCenter />}
            />
            <Route
              path="/reports/rel-estoque-ajuste"
              element={<InventoryAdjustment />}
            />
            <Route
              path="/reports/central-compras"
              element={<PurchaseCenter />}
            />
            <Route
              path="/reports/rel_controle_contas"
              element={<FinancialAccountsControl />}
            />
            <Route
              path="/reports/rel_controle_credito"
              element={<AccountsControlCredit />}
            />
            <Route
              path="/reports/rel_controle_contas_pagar"
              element={<AccountsControlExpense />}
            />
            <Route
              path="/reports/rel_controle_contas_receber"
              element={<AccountsControlReceive />}
            />
            <Route path="/reports/portadores" element={<Carriers />} />
            <Route path="/reports/fluxo-caixa" element={<CashFlow />} />
            <Route path="/reports/impostos" element={<Taxes />} />
            <Route
              path="/reports/cliente-frequencia"
              element={<CustomerFrequency />}
            />
            <Route
              path="/reports/curva-evolucao"
              element={<EvolutionCurve />}
            />
            <Route path="/reports/devolucoes" element={<InventoryReturns />} />
            <Route
              path="/reports/estoque-atual"
              element={<CurrentInventory />}
            />
            <Route
              path="/reports/rel-movimento-estoque"
              element={<InventoryMovement />}
            />
            <Route
              path="/reports/inventario-estoque"
              element={<GlobalInventory />}
            />
            <Route
              path="/reports/controle-tanques"
              element={<TanksControl />}
            />
            <Route path="/reports/gmad-campanha" element={<GmadCampaign />} />
            <Route path="/reports/rel-gmad-metas" element={<BrandGoals />} />
            <Route
              path="/reports/rel-clientes-um-depto"
              element={<CustomerOneDepartment />}
            />
            <Route
              path="/reports/rel-campanhas-rede"
              element={<StoreGroupCampaings />}
            />
            <Route path="/reports/rel-loja-grupo" element={<StoreGroup />} />
            <Route
              path="/reports/rel-programa-vendedores"
              element={<GmadSalesmanProgram />}
            />
            <Route
              path="/reports/rel-extrato-ranking"
              element={<GmadRankingExtract />}
            />
            <Route
              path="/reports/rel-extrato-campanha"
              element={<GmadCampaignsExtract />}
            />
            <Route path="/reports/rel-metas" element={<GoalTracking />} />
            <Route
              path="/reports/rel-vendas-mil-litros"
              element={<Sales1kLiters />}
            />
            <Route
              path="/reports/rel-postos-quadro-indicadores"
              element={<IndicatorBoard />}
            />
            <Route
              path="/reports/rel_comparativo_postos"
              element={<GasStationComparsion />}
            />
            <Route
              path="/reports/rel-descontos-combustiveis"
              element={<FuelDiscount />}
            />
            <Route
              path="/reports/rel_conciliacao_venda"
              element={<FuelDiscountNew />}
            />
            <Route
              path="/reports/rel-meta-combustiveis"
              element={<FuelTargets />}
            />
            <Route
              path="/reports/:datasetId/:reportId/:id/:reportName"
              element={<RenderPowerBI />}
            />
            <Route
              path="/reports/rel-despesas-frota"
              element={<FleetExpenses />}
            />
            <Route
              path="/reports/analise-compras"
              element={<PurchaseAnalysis />}
            />
            <Route
              path="/reports/acompanhamento-visitas"
              element={<VisitTracking />}
            />
            <Route path="/reports/pedidos-compra" element={<PurchaseOrder />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const hasReport = useHasReport(pathname);

  useEffect(() => {
    if (!hasReport) {
      navigate("/");
    }
  }, [hasReport, navigate, pathname]);

  return (
    <>
      <Navbar />
      <ErrorBoundary
        FallbackComponent={({ resetErrorBoundary }) => (
          <ErrorComponent handleReload={resetErrorBoundary} showSuportMessage />
        )}
      >
        <Outlet />
      </ErrorBoundary>
    </>
  );
}
